import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';

import { banners } from '../../../static/data';
import styles from '../../../styles/styles';

const Banners = () => {
  return (
    <>
      <section className={`${styles.section_banner} mb-6 mt-2`}>
        <Swiper
          cssMode={true}
          mousewheel={true}
          navigation={true}
          pagination={true}
          loop={true}
          slidesPerView={1}
          keyboard={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }}
          // modules={[Pagination]}
        >
          {banners.map((banner) => (
            <SwiperSlide>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <img className="w-full h-full object-cover" src={banner.image_Url} alt="banner" />
              </div>
              {/*<section className="relative mr-0">*/}
              {/*  <img className="h-72 w-full rounded-b object-cover" src={banner.image_Url} alt="banner" />*/}
              {/*</section>*/}
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
};

export default Banners;
