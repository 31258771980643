import { MdLocationPin, MdVerifiedUser } from 'react-icons/md';
import { IoCard } from 'react-icons/io5';
import styles from '../../styles/styles';
import { CHECKOUT_STEPS } from '../../utils/constants/literalStrings';
import { useMobile } from '../../hooks/common';

const CheckoutSteps = ({ stepInfo }) => {
  const isMobile = useMobile();
  const active = stepInfo.number;
  return (
    <section className="mx-auto my-0 w-full">
      <div className="mb-10 flex w-full items-center justify-center">
        <h1 className="text-center text-xl font-bold">Checkout</h1>
      </div>
      <div className="mb-10 flex w-full items-center justify-center">
        <div className="flex w-full items-center justify-center">
          <section className="flex flex-col">
            <div className={`${styles.noramlFlex}`}>
              <div className={`${styles.cart_button} bg-primary-200`}>
                <MdLocationPin className={`h-8 w-8 text-white `} />
              </div>
              <div className={`h-[4px] ${isMobile ? 'w-[80px]' : 'w-[150px]'} ${active > 1 ? ' !bg-primary-500' : ' !bg-gray-200'}`} />
            </div>
            <p className="self-left">{CHECKOUT_STEPS.delivery.name}</p>
          </section>
          <section className="flex flex-col">
            <div className={`${styles.noramlFlex}`}>
              <div className={`${active > 1 ? `${styles.cart_button}` : `${styles.cart_button} !bg-gray-200`}`}>
                <IoCard className={`h-8 w-8 ${active > 1 ? 'text-white' : 'text-primary-500'} `} />
              </div>
            </div>
            <p className="self-center">{CHECKOUT_STEPS.payment.name}</p>
          </section>
          <section className="flex flex-col justify-end">
            <div className={`${styles.noramlFlex}`}>
              <div className={`h-[4px] ${isMobile ? 'w-[80px]' : 'w-[150px]'} ${active > 2 ? ' !bg-primary-500' : ' !bg-gray-200 '}`} />
              <div className={`${active > 2 ? `${styles.cart_button}` : `${styles.cart_button} !bg-gray-200`}`}>
                <MdVerifiedUser className={`h-8 w-8  ${active > 2 ? 'text-white' : 'text-primary-500'} `} />
              </div>
            </div>
            <p className="self-end">{CHECKOUT_STEPS.confirmation.name}</p>
          </section>
        </div>
      </div>
    </section>
  );
};

export default CheckoutSteps;
