import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import useCategories from '../../hooks/categories/useCategories';

const CategoriesMobile = () => {
  const { categories } = useCategories();

  const navigate = useNavigate();
  return (
    <div
      className="flex overflow-x-auto whitespace-nowrap gap-[15px] px-[10px] py-[5px] scroll-smooth scroll-thin"
      style={{ touchAction: 'pan-x' }}
    >
      {Array.isArray(categories) &&
        categories.map((category) => {
          const handleSubmit = () => {
            navigate(`/products?category=${category.name}`);
          };

          return (
            <button
              key={category.name}
              onClick={handleSubmit}
              className="flex-none px-[16px] py-[10px] gap-[10px] rounded-full bg-[#FEE5CD] text-sm font-medium whitespace-nowrap hover:bg-[#fcd6b4] transition-colors whitespace-nowrap"
            >
              {category.name}
            </button>
          );
        })}
    </div>
  );
};

export default CategoriesMobile;
