export const styles = {
  // Contenedor principal
  OTPInputContainer: 'relative flex flex-col items-center w-full',
  // Grid responsivo: en pantallas muy pequeñas 2 columnas, en sm 3 columnas y en md o más 6 columnas
  SplitOTPBoxesContainer: 'grid grid-cols-6 sm:grid-cols-6 md:grid-cols-6 gap-2 w-full',
  // Input oculto para capturar el código sin interferir en la UI
  TextInputHidden: 'absolute opacity-0 pointer-events-none',
  // Casilla fija: con dimensiones fijas para evitar cambios de tamaño y con transición para el focus
  SplitBoxes: 'flex items-center justify-center border-2 border-primary-200 p-4 w-10 h-10 sm:w-12 sm:h-12 md:h-12 md:w-12 rounded-md transition-all duration-200',
  SplitBoxText: 'text-md text-center text-primary-500',
  // Estilo extra para la casilla en foco, sin alterar las dimensiones
  SplitBoxesFocused: 'border-primary-500 bg-gray-200',
};