import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrash } from 'react-icons/bs';

import { parseToUpdateCart } from '../../../utils/parseData/reduxCart';
import { updateCart, removeFromCart } from '../../../redux/actions/cart';

import styles from '../../../styles/styles';
import { MdOutlineHideImage } from 'react-icons/md';

const SingleProductcart = ({ data, shopId }) => {
  // @ts-ignore
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const [value, setValue] = useState(data.quantity);
  const totalPrice = Number(data.price * value).toFixed(2);

  const updateProductCart = (newQuantity: number) => {
    const cartData = parseToUpdateCart({ id: shopId, product: { ...data, quantity: newQuantity }, cart: { ...cart } });
    // @ts-ignore
    dispatch(updateCart(cartData));
  };

  const increment = () => {
    // if (data.stock < value) {
    //   toast.error('Product stock limited!');
    // }
    const quantity = value + 1;
    setValue(quantity);
    updateProductCart(quantity);
  };

  const decrement = () => {
    // if (value === 1) {
    //   const removeShopProduct = { _id: shopId, productId: data._id };
    //   // @ts-ignore
    //   dispatch(removeFromCart(removeShopProduct));
    //   return;
    // }
    setValue(value - 1);
    updateProductCart(value - 1);
  };
  const removeFromCartHandler = () => {
    const removeShopProduct = { _id: shopId, productId: data._id };

    // @ts-ignore
    dispatch(removeFromCart(removeShopProduct));
  };
  return (
    <section className="mb-4 rounded-md border border-slate-200 py-2 pr-4">
      <div className="flex">
        <div className="ml-2 mr-2 h-24 w-32 flex items-center justify-center rounded-[5px] bg-gray-50 overflow-hidden">
          {data?.images?.[0]?.url ? (
            <img
              src={data.images[0].url}
              alt="Producto"
              className="h-full w-full object-contain"
            />
          ) : (
            <MdOutlineHideImage className="text-gray-300 text-4xl" />
          )}
        </div>
        <div className="flex w-full flex-col items-center">
          <div className="w-full pl-[5px]">
            <p className="pt-[3px] text-[17px] text-lg">C${data.price}</p>

            <h4 className="text-sm text-gray-600">{data.name}</h4>
          </div>
          {/* Handle quantity button */}
          <div className="flex w-2/4 items-center justify-between self-end pt-2">
            {value === 1 && (
              <>
                <button className={`${styles.cart_decrement_button}`}>
                  <BsTrash className="self-center text-white" onClick={removeFromCartHandler} />
                </button>
              </>
            )}
            {value > 1 && (
              <button className={`${styles.cart_decrement_button} text-xl`} onClick={decrement}>
                <span className="self-center text-white">-</span>
              </button>
            )}

            <span className="pl-[10px]">{data.quantity}</span>

            <button className={`${styles.cart_add_button} text-xl`} onClick={increment}>
              <span className="self-center">+</span>
            </button>
            {/* <BsTrash className="ml-2 cursor-pointer" onClick={removeFromCartHandler} /> */}
          </div>
          {/* ENd Handle quantity button */}
        </div>
      </div>
    </section>
  );
};

export default SingleProductcart;
