// @ts-nocheck
import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux.hooks';
import { setCheckoutInfo } from '../../../../../redux/slices/checkoutSlice';
import { DAYS } from '../../../../../utils/constants/literalStrings';
import DeliveryInterval from '../DeliveryInterval';
import useDeliveryDate from '../../../../../hooks/checkout/deliveryDate.hooks';
import { useMobile } from '../../../../../hooks/common';

const DeliveryDate = () => {
  const { loading, data, error } = useDeliveryDate();
  const isMobile = useMobile();
  const { checkoutForms } = useAppSelector((state) => state.checkout);
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState();
  const now = new Date();
  const currentDay = now.getDay() + 1;
  const handleSelectDate = (deliveryDate) => {
    setSelectedDate(deliveryDate);
    dispatch(setCheckoutInfo({ ...checkoutForms, deliveryDate: deliveryDate.date }));
  };

  if (loading || error || !data) {
    return <Skeleton />;
  }
  const daysOrder = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  };

  const sortedDays = data.allDeliverySchedule[0].default.businessDays.sort((a, b) => daysOrder[a.day] - daysOrder[b.day]);
  const currentIndex = sortedDays.findIndex((item) => daysOrder[item.day] === currentDay);
  const reorderedBusinessDay = [...sortedDays.slice(currentIndex), ...sortedDays.slice(0, currentIndex)].map((businessDay) => {
    const today = new Date();
    const currentDayOrder = daysOrder[businessDay.day];
    const daysToAdd = currentDayOrder >= currentDay ? currentDayOrder - currentDay : 7 + currentDayOrder - currentDay;
    today.setDate(today.getDate() + daysToAdd + 1);

    const formattedDate = new Intl.DateTimeFormat('es', { day: '2-digit', month: 'short' }).format(today);
    return {
      ...businessDay,
      day: DAYS[businessDay.day],
      formattedDate,
      date: today.toISOString(),
    };
  });

  return (
    <>
      {data && Array.isArray(data?.allDeliverySchedule) && (
        <section className={`mt-6`}>
          <p className="text-lg font-bold">Entrega</p>
          <span className="mb-2 text-sm text-gray-400">Cuando lo entregamos?</span>
          <section className={`flex flex-row gap-2 ${isMobile && 'w-full overflow-x-scroll'}`}>
            {reorderedBusinessDay.map((businessDay, idx) => (
              <button
                className={`flex ${isMobile && 'flex-none'} items-center gap-1 rounded-xl border-2 border-gray-200 px-4 py-2 ${
                  selectedDate?._id === businessDay._id && 'border-primary-200'
                }`}
                onClick={() => handleSelectDate(businessDay)}
              >
                <p>
                  {idx === 0 ? (
                    'Mañana'
                  ) : (
                    <>
                      {businessDay.day} {businessDay.formattedDate}
                    </>
                  )}
                </p>
                {selectedDate && selectedDate._id === businessDay._id && <FaCheck className="text-primary-500" />}
              </button>
            ))}
          </section>
        </section>
      )}
      {selectedDate && <DeliveryInterval workHours={selectedDate.work_hours} />}
    </>
  );
};

export default DeliveryDate;
