import { useState } from 'react';

import { APP_CONFIG } from '../../../../utils/constants/common';
import MKDrawer from '../../../UI/MKDrawer';
import MKOTP from '../../../UI/MKOtp';
import MKButton from '../../../UI/MKButton';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie/dist';
import loadingDefault from '../../../../Assests/animations/loading_blue_simple.json';
import { twMerge } from 'tailwind-merge';

interface ICodeVerificationDrawerProps {
  isOpen?: boolean;
  isLoading?: boolean;
  phoneNumber?: string;
  handleCloseDrawer: () => void;
  setOpenCart?: (boolean) => void;
  handleVerifyOtp?: (otpCode?: string) => void;
}
const CodeVerificationDrawer = ({ isOpen, setOpenCart, isLoading, phoneNumber, handleVerifyOtp, handleCloseDrawer }: ICodeVerificationDrawerProps) => {
  const [code, setCode] = useState('');

  const handleVerifyPhoneNumber = async () => {
    if (code.length !== 6) {
      toast.warning('Por favor ingresa completamente los 6 dígitos');
      return;
    }
    
    handleVerifyOtp(code);
  };
  
  const loadingDefaultOption = {
    loop: true,
    autoplay: true,
    animationData: loadingDefault,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  
  return (
    <>
      <div
        className={twMerge(
          'fixed inset-0 z-40 bg-black/40 transition-opacity duration-300',
          isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        )}
        onClick={() => handleCloseDrawer() } // Cierra al hacer clic fuera del Drawer
      >
        <div
          className={twMerge(
            'fixed right-0 top-0 z-50 h-full bg-white shadow-sm flex flex-col',
            'transition-transform duration-300 overflow-hidden', // Evita scroll no deseado
            isOpen ? 'translate-x-0' : 'translate-x-full',
            'w-full sm:w-[90%] md:w-[70%] lg:w-[50%] xl:w-[30%] 2xl:w-[25%]'
          )}
          onClick={(e) => e.stopPropagation()} // Evita cerrar al hacer clic dentro
        >
          <section className="flex flex-col px-3 py-6">
            <button className="absolute right-4 top-1" onClick={handleCloseDrawer}>
              <IoClose className="h-8 w-8 hover:text-primary-500" />
            </button>
            <h3 className="text-2xl">Ingresa el código</h3>
            <p className="pb-4 pt-2">
              Enviamos un código a{' '}
              <span className="text-primary-200">
                {APP_CONFIG.countryCode} {phoneNumber}
              </span>{' '}
              para validar tu identidad.
            </p>
            <MKOTP code={code} setCode={setCode} />
            {!isLoading ? (
              <>
                <MKButton onClick={handleVerifyPhoneNumber} className="my-8 w-full self-center md:w-2/4">
                  <p className="text-center text-white">Verificar código</p>
                </MKButton>
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <Lottie options={loadingDefaultOption} width={100} height={100} />
                <p className="text-sm font-bold">Verificando...</p>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default CodeVerificationDrawer;
