import React, { useRef, useState } from 'react';
import BoxDigit from './BoxDigit';
import { styles } from './styles';

interface MKOTPProps {
  pinCount?: number;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}
const MKOTP = ({ pinCount = 6, code, setCode }: MKOTPProps) => {
  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  
  const handleOnBlur = () => {
    setIsInputBoxFocused(false);
  };
  
  const handleOnFocus = () => {
    setIsInputBoxFocused(true);
  };
  
  // Al hacer click en el contenedor se enfoca el input oculto
  const handleContainerClick = () => {
    inputRef.current?.focus();
  };
  
  return (
    <section className={styles.OTPInputContainer} onClick={handleContainerClick}>
      <section className={styles.SplitOTPBoxesContainer}>
        {Array.from({ length: pinCount }, (_, index) => (
          <BoxDigit
            key={index}
            code={code}
            index={index}
            pinCount={pinCount}
            isInputBoxFocused={isInputBoxFocused}
          />
        ))}
      </section>
      <input
        className={styles.TextInputHidden}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        maxLength={pinCount}
        ref={inputRef}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      />
    </section>
  );
};

export default MKOTP;
