import { styles } from '../styles';

interface IBoxDigitProps {
  code: string;
  index: number;
  pinCount: number;
  isInputBoxFocused: boolean;
}
const BoxDigit = ({ code, index, pinCount, isInputBoxFocused }: IBoxDigitProps) => {
  const digit = code[index] || '';
  // Determina si es la casilla que debe recibir el focus
  const isCurrentValue = index === code.length;
  const isLastValue = index === pinCount - 1;
  const isCodeComplete = code.length === pinCount;
  const isValueFocused = isInputBoxFocused && (isCurrentValue || (isLastValue && isCodeComplete));
  
  return (
    <section className={`${styles.SplitBoxes} ${isValueFocused ? styles.SplitBoxesFocused : ''}`}>
      <p className={styles.SplitBoxText}>
        {digit || (isValueFocused && <span className="animate-blink">|</span>)}
      </p>
    </section>
  );
};

export default BoxDigit;
