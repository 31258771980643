import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { MdOutlineHideImage } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';

import { IShop } from '../../../interfaces/shop';
import styles from '../../../styles/styles';
import ProductCard from '../../Route/ProductCard/ProductCard';

interface ISingleShopProducts {
  shopProducts: IShop[];
}

const SingleShopProducts = ({ shopProducts }: ISingleShopProducts) => {
  const shopSkeleton = new Array(4).fill(0);
  return (
    <>
      {shopProducts.map(({ _id, name, avatar, products }) => (
        <section key={`sp-${_id}`} className={`${styles.section} pb-8`}>
          <Link
            to={`/shop/${_id}`}
            className="group flex items-center justify-between pb-4 w-full transition-shadow duration-200 cursor-pointer"
          >
            {/* Imagen y nombre */}
            <div className="flex items-center">
              {avatar !== null && typeof avatar === 'object' && avatar.url ? (
                <img
                  src={avatar?.url}
                  alt=""
                  className="h-[50px] w-[50px] rounded-xl object-cover transition-opacity duration-200 group-hover:opacity-90"
                />
              ) : (
                <MdOutlineHideImage className="h-[50px] w-[50px] rounded-xl text-gray-400 transition-opacity duration-200 group-hover:opacity-90" />
              )}
              <h1 className="ml-2 font-semibold text-gray-800 transition-colors duration-200 group-hover:text-primary-500">
                {name}
              </h1>
            </div>
            
            {/* Botón flecha */}
            <div className="ml-auto inline-block">
              <div className="h-10 w-10 flex items-center justify-center rounded-full border border-gray-200 bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                <IoIosArrowForward className="text-gray-600" />
              </div>
            </div>
          </Link>
          <section className="px-6">
            {products && (
              <>
                <Swiper
                  cssMode={true}
                  mousewheel={true}
                  spaceBetween={10}
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    780: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    800: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Pagination, Mousewheel, Navigation]}
                >
                  {products.map((product) => (
                    <>
                      <SwiperSlide key={`sp-product${product._id}`} className="product_card">
                        <ProductCard data={product} shopId={_id} />
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              </>
            )}
          </section>
        </section>
      ))}

      {(!shopProducts || (Array.isArray(shopProducts) && shopProducts.length === 0)) && (
        <section className="w-full px-8">
          {shopSkeleton.map((_, index) => (
            <div key={`skeleton-shop-products-${index}`} className={`w-full pb-8`}>
              <div className=" w-full p-4">
                <div className="flex animate-pulse flex-col space-x-4 space-y-4">
                  <div className="h-10 w-1/2 rounded-md bg-slate-200" />
                  <div className="flex flex-row flex-wrap gap-4 py-1">
                    <div className="h-60 w-60 rounded bg-slate-200"></div>
                    <div className="h-60 w-60 rounded bg-slate-200"></div>
                    <div className="h-60 w-60 rounded bg-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export default SingleShopProducts;
