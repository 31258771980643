import { useState, useEffect } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProductsInCart from './ProductsInCart';
import CleanCart from './CleanCart';
import { useAppSelector } from '../../hooks/redux.hooks';
import MKModal, { CloseModal } from '../UI/MKModal';
import CustomerEmptyAddress from '../Common/CustomerEmptyAddress';
import { twMerge } from 'tailwind-merge';

const Cart = ({ setOpenCart, shopId, isOpen }) => {
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);
  const [currentShopProducts, setCurrentShopProducts] = useState(cart[shopId]);
  const totalPrice = currentShopProducts?.products?.reduce((acc, item) => acc + item?.quantity * item?.price, 0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // bloquea scroll del fondo
    } else {
      document.body.style.overflow = 'auto'; // restaura scroll cuando se cierra
    }

    // Limpieza por si el componente se desmonta
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleCheckoutClick = () => {
    if (typeof user === 'undefined') {
      navigate(`/login`);
      return;
    }
    if (!Array.isArray(user?.addresses) || user?.addresses.length === 0) {
      setShowModal(true);
      return;
    }
    navigate(`/shop/${shopId}/checkout`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setCurrentShopProducts(cart[shopId]);
  }, [cart, shopId]);

  return (
    <div
      className={twMerge(
        'fixed inset-0 z-40 bg-black/40 transition-opacity duration-300',
        isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      )}
      onClick={() => setOpenCart(false)} // Cierra al hacer clic fuera del Drawer
    >
      <div
        className={twMerge(
          'fixed right-0 top-0 z-50 h-full bg-white shadow-sm flex flex-col',
          'transition-transform duration-300 overflow-hidden', // Evita scroll no deseado
          isOpen ? 'translate-x-0' : 'translate-x-full',
          'w-full sm:w-[90%] md:w-[70%] lg:w-[50%] xl:w-[30%] 2xl:w-[25%]'
        )}
        onClick={(e) => e.stopPropagation()} // Evita cerrar al hacer clic dentro
      >
        {(!currentShopProducts ||
          !Array.isArray(currentShopProducts.products) ||
          currentShopProducts.products.length === 0) ? (
          // Carrito vacío
          <div className="flex h-full w-full items-center justify-center px-5">
            <div className="absolute right-3 top-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
              />
            </div>
            <h5>El carrito está vacío!</h5>
          </div>
        ) : (
          <>
            {/* Header */}
            <header className="flex items-center justify-between px-5 py-5 border-b border-slate-200">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
              />
              <p className="font-medium">Carrito</p>
              <CleanCart shopId={shopId} />
            </header>

            {/* Cart Items: scroll vertical */}
            <div className="flex-grow overflow-y-auto overflow-x-hidden px-3 py-4">
              <ProductsInCart products={currentShopProducts.products} shopId={shopId} />
            </div>

            {/* Checkout (parte inferior fija) */}
            <footer className="border-t border-slate-200 px-5 py-4">
              <div className="pb-2">
                <p className="text-center text-slate-500">
                  Tus compras siempre seguras con
                </p>
                <p className="text-center text-primary-200">@merkoline</p>
              </div>
              <div className="flex items-center justify-between">
                <p>
                  Subtotal:{' '}
                  <span className="text-2xl font-black">
                    C$ {totalPrice?.toFixed(2)}
                  </span>
                </p>
                <button onClick={handleCheckoutClick}>
                  <div className="flex h-[45px] items-center justify-center rounded-xl bg-primary-200 px-8">
                    <h1 className="text-[18px] font-[600] text-white">Checkout</h1>
                  </div>
                </button>
              </div>
            </footer>
          </>
        )}
      </div>

      {/* Modal de dirección (si aplica) */}
      {showModal && (
        <section className="relative">
          <MKModal>
            <section className="relative">
              <CloseModal className="z-10" onClick={handleCloseModal} />
              <CustomerEmptyAddress />
            </section>
          </MKModal>
        </section>
      )}
    </div>
  );
};

export default Cart;