import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { ICustomer } from '../interfaces/auth';
import { IFetchState } from '../interfaces/common';


interface UserSessionStore {
  userSession: ICustomer | null;
  userFetchState: IFetchState;
  token: string | null; // if the user has token means the user is logged
  singInUser: (user: ICustomer, token: string) => void;
  tokenForVerifyOTP: string | null;
  tokenForRegister: string | null;
  setTokenForVerifyOTP: (tokenVerifyOTP?: string) => void;
  setTokenForRegister: (tokenRegister?: string) => void;
  setUserLoginLoading: (loading?: boolean) => void;
  setUserLoginError: (error?: boolean, message?: string) => void;
  refreshUserSession: (driver?: ICustomer, token?: string) => void;
  clearUserLoginState: () => void;
  logoutUser: () => void;
}

export const useUserSessionStore = create<UserSessionStore>()(
  persist(
    (set) => ({
      userSession: null,
      userFetchState: { loading: false, error: false },
      token: null,
      singInUser: (user: ICustomer, token) =>
        set({ userSession: user, token, tokenForRegister: null, tokenForVerifyOTP: null}),
      tokenForVerifyOTP: null,
      tokenForRegister: null,
      
      setTokenForVerifyOTP: (tokenVerifyOTP?: string) =>
        set({ tokenForVerifyOTP: tokenVerifyOTP }),
      
      setTokenForRegister: (tokenRegister?: string) =>
        set({ tokenForRegister: tokenRegister }),
      
      refreshUserSession: (user?: ICustomer, token?) => {
        if (user && token) {
          set({ userSession: user, token, tokenForRegister: null, tokenForVerifyOTP: null });
          return;
        }
        
        if (token) {
          set({ token, tokenForRegister: null, tokenForVerifyOTP: null });
          return;
        }
        
        if (user) {
          set({ userSession: user, tokenForRegister: null, tokenForVerifyOTP: null });
          return;
        }
      },
      logoutUser: () => set({ userSession: null, token: null, userFetchState: null, tokenForRegister: null, tokenForVerifyOTP: null }),
      setUserLoginLoading: (loading = true) =>
        set({
          userFetchState: { loading: loading, error: false, message: '' },
        }),
      setUserLoginError: (error = true, message = '') =>
        set({ userFetchState: { loading: false, error: error, message } }),
      clearUserLoginState: () =>
        set({
          userFetchState: { loading: false, error: false, message: null },
        }),
    }),
    {
      name: 'user-login-session',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
