import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getCountryDataList, getEmojiFlag } from 'countries-list';

import CodeVerificationDrawer from './CodeVerificationDrawer';
import MKInput from '../../UI/MKInput';
import MKButton from '../../UI/MKButton';

import {
  LOGIN_OR_REGISTER_WITH_PHONE_NUMBER,
  SIGN_UP_WITH_PHONE,
  VERIFY_OTP_FOR_LOGIN_OR_REGISTER
} from '../../../services/auth/login';
import { useUserSessionStore } from '../../../store/userSessionStore';
import { loadUser } from '../../../redux/actions/user';
import { useAppDispatch } from '../../../hooks/redux.hooks';
import { useNavigate } from 'react-router-dom';
import SignUpWithPhoneDrawer from './SignUpWithPhoneDrawer';
import Lottie from 'react-lottie/dist';
import loadingDefault from '../../../Assests/animations/loading_blue_simple.json';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../../UI/MKAlertDialog';
import Cookies from 'js-cookie';

const LoginNumberMethod = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirmCancel, setIsShowConfirmCancel] = useState(false);
  const [phoneNumber, setphoneNumber] = useState('');
  
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCountry, setselectedCountry] = useState('US');
  const {
    setTokenForVerifyOTP,
    setTokenForRegister,
    tokenForVerifyOTP,
    tokenForRegister,
    logoutUser,
    singInUser
  } = useUserSessionStore();

  const whiteList = ['US', 'NI', 'CA', 'ES'];

  const allCountries = getCountryDataList().filter((item) => whiteList.includes(item.iso2.toUpperCase()));
  // Reglas de validación por país
  const phoneValidationRules = {
    US: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/, // Ejemplo para EE.UU. (10 dígitos)
    NI: /^\d{8}$/, // Ejemplo para Nicaragua (8 dígitos)
    CA: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/, // Ejemplo para Canadá (10 dígitos)
    ES: /^\d{9}$/, // Ejemplo para España (9 dígitos)
  };
  
  useEffect(() => {
    if (tokenForVerifyOTP || tokenForRegister) {
      console.log('tokenForRegister', tokenForRegister);
      setOpenDrawer(true);
    }
  }, []);

  const getCountryCodeSelected = (selectedCountry: string) => {
    const country = getCountryDataList().find((c) => c.iso2 === selectedCountry);
    if (country) {
      try {
        return `${country.phone?.[0]}`;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  };

  const validatePhoneNumber = (countryCode, phone) => {
    const regex = phoneValidationRules[countryCode];
    return regex ? regex.test(phone) : false;
  };

  const handleLoginOrRegisterWithPhoneNumber = async () => {
    if (!validatePhoneNumber(selectedCountry, phoneNumber)) {
      toast.error('Ingrese un número válido para el país seleccionado');
      return;
    }
    const countryCodeValue = getCountryCodeSelected(selectedCountry);
    if (!countryCodeValue) {
      toast.error('El pais seleccionado no es valido');
      return;
    }
    
    setIsLoading(true);
    const sendOTPCodeResponse = await LOGIN_OR_REGISTER_WITH_PHONE_NUMBER(countryCodeValue, phoneNumber);
    setIsLoading(false);
    if (sendOTPCodeResponse?.success === true) {
      setTokenForVerifyOTP(sendOTPCodeResponse?.token);
      setOpenDrawer(true);
    }
  };
  
  const handleVerificationOfOTP = async (otpValue?: string) => {
    setIsLoading(true);
    const verifyOtpResponse = await VERIFY_OTP_FOR_LOGIN_OR_REGISTER(tokenForVerifyOTP, otpValue);
    setIsLoading(false);
    if (verifyOtpResponse?.success === true) {
      const { requiredSignUp, token, customer } = verifyOtpResponse || {};
      
      if (requiredSignUp === true) {
        setTokenForRegister(token);
      } else {
        toast.success('Ha iniciado sessión correctamente!');
        // setOpenDrawer(false);
        Cookies.set('token', `${token}`);
        // link to main
        await dispatch(loadUser());
        singInUser(customer, token);
        navigate('/');
      }
    } else {
      const { unauthorized, error, message } = verifyOtpResponse || {};
      
      if (unauthorized) {
        toast.error('Su session ha caducado por favor vuelva a iniciar session');
        logoutUser();
        navigate('/login');
        return;
      }
      
      if (error) {
        toast.error(message);
        return;
      }
    }
  };
  
  const handleSignUpWithPhoneNumber = async (fullNameValue?: string) => {
    setIsLoading(true);
    const signUpResponse = await SIGN_UP_WITH_PHONE(tokenForRegister, fullNameValue);
    setIsLoading(false);
    
    if (signUpResponse?.success === true) {
      const { token, customer } = signUpResponse || {};
      toast.success('Ha iniciado sessión correctamente!');
      // setOpenDrawer(false);
      // link to main
      Cookies.set('token', `${token}`);
      await dispatch(loadUser());
      singInUser(customer, token);
      navigate('/');
    } else {
      const { unauthorized, error, message } = signUpResponse || {};
      if (unauthorized) {
        toast.error('Su session ha caducado por favor vuelva a iniciar session');
        logoutUser();
        navigate('/login');
        return;
      }
      
      if (error) {
        toast.error(message);
        return;
      }
    }
  };
  
  const handleCloseDrawer = async () => {
    setIsShowConfirmCancel(false);
    setOpenDrawer(false);
    logoutUser();
  };
  
  const handleSelectCountry = (value: string) => {
    setselectedCountry(value);
  };
  
  const renderDrawer = () => {

    return (
      <div>
        <SignUpWithPhoneDrawer
          isOpen={openDrawer && !!tokenForRegister}
          isLoading={isLoading}
          handleCloseDrawer={ () => setIsShowConfirmCancel(true) }
          handleSignUp={handleSignUpWithPhoneNumber}
        />
        <CodeVerificationDrawer
          isOpen={openDrawer && !!tokenForVerifyOTP && !tokenForRegister}
          isLoading={isLoading}
          phoneNumber={phoneNumber}
          handleCloseDrawer={ () => setIsShowConfirmCancel(true) }
          handleVerifyOtp={handleVerificationOfOTP}
        />
      </div>
    )
  };
  
  const renderDialog = () => {
    return (
      <>
        <AlertDialog open={isShowConfirmCancel}>
          <AlertDialogContent >
            <AlertDialogHeader>
              <AlertDialogTitle>
                Estas seguro de querer salir?
              </AlertDialogTitle>
              <AlertDialogDescription>
                Estas a {tokenForRegister ? `un solo paso de registrarte` : 'pocos pasos de registrarte'}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => handleCloseDrawer()}>
                Si, quiero salir
              </AlertDialogCancel>
              <AlertDialogAction onClick={() => setIsShowConfirmCancel(false)}>
                Continuar con el registro
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
      );
  };
  
  const loadingDefaultOption = {
    loop: true,
    autoplay: true,
    animationData: loadingDefault,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  
  
  return (
    <>
      <section className="flex flex-col">
        <section className="flex flex-col justify-center">
          <h2 className="text-center text-base font-extrabold text-gray-900 md:mt-2 md:text-2xl">
            Ingresa tu número de teléfono para iniciar
          </h2>
        </section>
        <section className="my-2 flex w-full items-center rounded-xl border bg-gray-100 ">
          <select
            onChange={(e) => handleSelectCountry(e.target.value)}
            id="countries"
            className="ml-2 block w-10 border-r border-r-gray-400 bg-transparent text-xl text-gray-900 focus:border-primary-500 focus:ring-primary-500 "
          >
            {allCountries.map((country) => (
              <option value={country.iso2} selected={country.iso2 === selectedCountry}>
                <p className="text-3xl">{getEmojiFlag(country.iso2)}</p>
                <span className="ml-2 text-sm">{country.native}</span>
              </option>
            ))}
          </select>
          <MKInput
            className="ml-2 w-full rounded-none  border-none bg-transparent"
            type="number"
            placeholder="Número de teléfono"
            value={phoneNumber}
            onChange={(e) => setphoneNumber(e.target.value)}
          />
        </section>
        {!isLoading ? (
          <MKButton onClick={handleLoginOrRegisterWithPhoneNumber} className="my-4 w-full self-center md:w-2/4">
            <p className="text-center text-white">Continuar</p>
          </MKButton>
        ) : (
          <div className="flex w-full flex-col items-center justify-center">
            <Lottie options={loadingDefaultOption} width={100} height={100} />
            <p className="text-sm font-bold">Verificando...</p>
          </div>
        )}
        
      </section>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
};

export default LoginNumberMethod;
