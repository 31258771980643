import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';

import useCategories from '../../hooks/categories/useCategories';

const Categories = () => {
  const { categories } = useCategories();

  const navigate = useNavigate();
  return (
    <>
      <Swiper
        slidesPerView={2}
        cssMode={true}
        mousewheel={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 'auto',
            spaceBetween: 10,
          },
          1000: {
            slidesPerView: 'auto',
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination, Mousewheel]}
      >
        <div className="">
          {Array.isArray(categories) &&
            categories.map((category) => {
              const handleSubmit = (i) => {
                navigate(`/products?category=${i.name}`);
              };
              return (
                <SwiperSlide key={`sw-cat-${category._id}`} id={`category${category._id}`}>
                  <section className="mr-1 gap-1">
                    <>
                      <Link to={`categories/`} className="relative">
                        <div style={{ background: category.image.mainColor }} className="relative h-48 w-48 overflow-hidden rounded-md">
                          <div className="absolute inset-x-0 top-2 text-center text-white md:top-4">
                            <p className="line-clamp-2 text-sm md:text-base">{category.name}</p>
                          </div>

                          <div
                            className="absolute -bottom-1/3 left-0 right-0 ml-auto mr-auto h-40 w-40 rounded-full"
                            style={{ background: category.image.secondaryColor }}
                          />
                          <img
                            src={category.image.url}
                            alt=""
                            className="absolute bottom-0 left-0 right-0 ml-auto mr-auto w-full object-cover"
                          />
                        </div>
                      </Link>
                    </>
                  </section>
                </SwiperSlide>
              );
            })}
        </div>
      </Swiper>
    </>
  );
};

export default Categories;
