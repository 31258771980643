const styles = {
    custom_container: "w-11/12 hidden sm:block",
    heading: 'text-[27px] text-center md:text-start font-[600] pb-[20px]',
    subtitle: 'text-[18px] text-center md:text-start pb-[14px]',
    section:'w-11/12 mx-auto',
    section_banner:'w-full mx-auto',
    productTitle: 'text-[25px] font-[600] text-[#333]',
    productDiscountPrice:"font-black text-xl text-[#333] pb-1",
    price:"font-[500] text-sm text-gray-400 mt-[-4px] line-through",
    shop_name:"pt-3 text-[15px] pb-3",
    active_indicator:"absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
    button: 'w-[150px] text-center bg-primary-200 hover:bg-primary-500 text-white h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer',
    cart_button: "rounded-xl bg-primary-100 text-primary-500 font-bold text-lg flex items-center justify-center cursor-pointer p-2 h-10",
    cart_delete_button: "w-[40px] h-[40px] bg-red-400 p-2 rounded-lg hover:opacity-75 transition duration-300 ease-in-out flex justify-center items-center",
    cart_add_button: "py-2 text-white rounded-xl w-[40px] h-[40px] bg-primary-200 hover:opacity-75 transition duration-300 ease-in-out flex justify-center items-center",
    cart_decrement_button: "text-white py-2 rounded-xl w-[40px] h-[40px] bg-red-400 hover:opacity-75 transition duration-300 ease-in-out flex justify-center items-center",
    cart_button_text:"text-primary-500 text-[16px] font-[600]",
    input: "w-full border bg-gray-100 px-4 py-3 rounded-xl min-h-[50px] flex items-center text-gray-500",
    input_edit: "w-full border-slate-200 rounded-xl border-2 bg-white px-4 py-3 min-h-[50px] flex items-center text-black font-bold text-base",
    activeStatus:"w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
    noramlFlex:"flex items-center",
    fixedCardWidth: 'w[230px]'
  };
  
  export default styles;
  