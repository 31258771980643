import { useSelector } from 'react-redux';
import { CiReceipt } from 'react-icons/ci';
import { Link, useLocation } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { AiFillFacebook, AiFillInstagram, AiFillYoutube, AiOutlineTwitter } from 'react-icons/ai';

import { footerProductLinks, footerSupportLinks } from '../../static/data';
import styles from '../../styles/styles';
import { BiSolidCategory, BiSolidHome } from 'react-icons/bi';
import { FaReceipt } from 'react-icons/fa';

const Footer = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div className="hidden bg-[#000] text-white md:block">
        <div className="sm:gird-cols-3 grid grid-cols-1 gap-6 px-5 py-10 sm:px-8 sm:text-center lg:grid-cols-4">
          <ul className="flex flex-col items-center px-5 text-center sm:block sm:text-start">
            <img className="h-36 w-36" src="/assets/mercoline-logo-300x300.png" alt="" />
            <br />
            <div className="mt-[15px] flex items-center">
              <AiFillFacebook size={25} className="cursor-pointer" />
              <AiOutlineTwitter size={25} style={{ marginLeft: '15px', cursor: 'pointer' }} />
              <AiFillInstagram size={25} style={{ marginLeft: '15px', cursor: 'pointer' }} />
              <AiFillYoutube size={25} style={{ marginLeft: '15px', cursor: 'pointer' }} />
            </div>
          </ul>

          <ul className="text-center sm:text-start">
            <h1 className="mb-1 font-semibold">Company</h1>
            {footerProductLinks.map((link, index) => (
              <li>
                <Link
                  className="cursor-pointer text-sm leading-6
                    text-gray-400 duration-300 hover:text-teal-400"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="text-center sm:text-start">
            <h1 className="mb-1 font-semibold">Soporte</h1>
            {footerSupportLinks.map((link, index) => (
              <li>
                <Link
                  className="cursor-pointer text-sm leading-6
                    text-gray-400 duration-300 hover:text-teal-400"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div
          className="grid grid-cols-1 gap-10 pb-8 pt-2
          text-center text-sm text-gray-400 sm:grid-cols-2 lg:grid-cols-3"
        >
          <span>© 2023 Mercoline. Todos los derechos reservados.</span>
          <span>Terminos · Politicas de privacidad</span>
          <div className="flex w-full items-center justify-center sm:block">
            <img
              src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
              alt=""
            />
          </div>
        </div>
      </div>
      <section className={`${styles.noramlFlex} fixed bottom-0 left-0 right-0 z-50 justify-around border-t-2 bg-white p-4 md:hidden`}>
        <div className="relative mr-[15px] cursor-pointer">
          <Link to="/">
            <BiSolidHome className={isActive('/') ? 'text-[#3A405A]' : 'text-[#C2C4CC]'} size={30} />
          </Link>
        </div>
        <div className="relative mr-[15px] cursor-pointer">
          <Link to="/categories">
            <BiSolidCategory className={isActive('/categories') ? 'text-[#3A405A]' : 'text-[#C2C4CC]'} size={30} />
          </Link>
        </div>
        <div className="relative mr-[15px] cursor-pointer">
          <Link to="/orders">
            <FaReceipt className={isActive('/orders') ? 'text-[#3A405A]' : 'text-[#C2C4CC]'} size={30} />
          </Link>
        </div>

        <div className="relative mr-[15px] hidden cursor-pointer md:block">
          {isAuthenticated ? (
            <Link to="/profile">
              <img src={`${user?.avatar?.url}`} className="h-[35px] w-[35px] rounded-full object-cover" alt="" />
            </Link>
          ) : (
            <Link to="/login">
              <CgProfile size={30} />
            </Link>
          )}
        </div>
      </section>
    </>
  );
};

export default Footer;
