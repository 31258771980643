/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getAllProductsShop } from '../../redux/actions/product';
import ProductInShopDetail from '../Route/ProductCard/ProductInShopDetail';
import useShopProducts from '../../hooks/shopProducts.hooks';
import ShopProductsSkeleton from './ShopProfile/ShopProductsSkeleton';

import styles from '../../styles/styles';
import { useMobile } from '../../hooks/common';

const ShopProfileData = ({ isOwner, shopDetails }) => {
  const { products } = useSelector((state) => state.products);
  const isMobile = useMobile();

  const { id } = useParams();
  const { loading } = useShopProducts(id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(id));
  }, [id]);

  if (loading) {
    return <ShopProductsSkeleton />;
  }


  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div>
          {isOwner && (
            <div>
              <Link to="/dashboard">
                <div className={`${styles.button} h-[42px] !rounded-[4px]`}>
                  <span className="text-[#fff]">Ir a Dashboard</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>

      <br />
      <div className={`mb-12 flex flex-wrap justify-between ${isMobile ? 'gap-2' : 'gap-4'}`}>
        {products &&
          products.map((i, index) => (
            <>
              <ProductInShopDetail shopDetails={shopDetails} data={i} key={index} isShopDetail={true} shopId={id} />
            </>
          ))}
      </div>

      {/* {active === 2 && (
        <div className="w-full">
          <div className="mb-12 grid grid-cols-1 gap-[20px] border-0 md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[20px]">
            {events && events.map((i, index) => <ProductCard data={i} key={index} isShop={true} isEvent={true} />)}
          </div>
          {events && events.length === 0 && <h5 className="w-full py-5 text-center text-[18px]">No Events have for this shop!</h5>}
        </div>
      )} */}

      {/* {active === 3 && <ShopReview />} */}
    </div>
  );
};

export default ShopProfileData;
