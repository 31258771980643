/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { MdOutlineHideImage } from 'react-icons/md';
import { AiOutlineCamera } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { getAllOrdersOfUser } from '../../redux/actions/order';
import CustomerAllOrders from './AllOrders';
import ChangePassword from './ChangePassword';
import UpdateUserInfo from './UpdateUserInfo';
import CustomerAddressList from './Address/CustomerAddressList';
import styles from '../../styles/styles';

const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [isEditingUser, setIsEditingUser] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (error && !user) {
      toast.error(error);
      return;
    }
    dispatch({ type: 'clearErrors' });
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: 'clearMessages' });
    }
  }, [error, successMessage]);

  return (
    <div className="w-full">
      {/* profile */}
      {active === 1 && (
        <>
          {!isEditingUser ? (
            <>
              <div className="mb-10 flex w-full justify-center">
                <div className="relative">
                  {user?.avatar?.url ? (
                    <img
                      src={`${user?.avatar?.url}`}
                      className="h-[150px] w-[150px] rounded-full border-[3px] border-primary-500 object-cover"
                      alt=""
                    />
                  ) : (
                    <div className="border-primary-5 flex h-36 w-36 items-center justify-center rounded-full border p-4">
                      <MdOutlineHideImage className=" h-28 w-28 text-gray-300 " />
                    </div>
                  )}
                  <div className="absolute bottom-[5px] right-[5px] flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-[#E3E9EE]">
                    <input disabled type="file" id="image" className="hidden" />
                    <AiOutlineCamera />
                  </div>
                </div>
              </div>
              <div className="w-full px-5">
                <section>
                  <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 ">
                    <div>
                      <p className="pb-2">Nombre completo</p>
                      <p type="text" className={`${styles.input} `}>
                        {user ? user?.name : ''}
                      </p>
                    </div>
                    <div>
                      <p className="pb-2">Correo</p>
                      <p type="text" className={`${styles.input}`}>
                        {user?.email}{' '}
                      </p>
                    </div>
                    <div>
                      <p className="block pb-2">Número de teléfono</p>
                      <p className={`${styles.input}`}>{user.phoneNumber || 'agrega tu número de teléfono'}</p>
                    </div>
                  </div>
                  <div className="justify-self-center">
                    <button
                      onClick={() => setIsEditingUser(true)}
                      className={`mt-8 h-[40px] w-[250px] cursor-pointer rounded-lg border border-primary-200 text-center text-primary-200`}
                    >
                      Editar
                    </button>
                  </div>
                </section>
              </div>
            </>
          ) : (
            <UpdateUserInfo />
          )}
        </>
      )}

      {/* order */}
      {active === 2 && (
        <div>
          <CustomerAllOrders />
        </div>
      )}

      {/* Refund */}
      {active === 3 && (
        <div>
          <AllRefundOrders />
        </div>
      )}

      {/* Track order */}
      {active === 5 && (
        <div>
          <TrackOrder />
        </div>
      )}

      {/* Change Password */}
      {active === 6 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/*  user Address */}
      {active === 7 && (
        <div>
          <CustomerAddressList />
        </div>
      )}
    </div>
  );
};

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eligibleOrders = orders && orders.filter((item) => item.status === 'Processing refund');

  return (
    <div className="pl-8 pt-1">
      {eligibleOrders &&
        eligibleOrders.map((eligibleOrder) => (
          <div>
            Cantidad:{eligibleOrder.cart.length}
            total: {eligibleOrder.totalPrice}
            Etado: {eligibleOrder.status}
          </div>
        ))}
    </div>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  return (
    <div className="pl-8 pt-1">
      {orders &&
        orders.map((order) => (
          <section>
            Productos:{order.cart.length}
            Total:{order.totalPrice}
            Estado:{order.status}
          </section>
        ))}
    </div>
  );
};

export default ProfileContent;
