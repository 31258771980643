import axios from 'axios';
import { API_URL_V3, API_URL_V2 } from '../../server';
import { APP_CONFIG } from '../../utils/constants/common';
import { ISignUpWithPhoneResponse } from '../../interfaces/auth';
import { ISignUpWithEmailResponse } from '../../interfaces/auth';
export const DEFAULT_ERROR_MESSAGE = 'Ha ocurrido un error';

export const SIGN_IN_WITH_PHONE_NUMBER = async (phoneNumber: string) => {
  try {
    const { data } = await axios.post(`${API_URL_V2}/user/login-with-number`, {
      phoneNumber: `${APP_CONFIG.countryCode}${phoneNumber}`,
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};

export const LOGIN_OR_REGISTER_WITH_PHONE_NUMBER =
  async (countryCode: string, phoneNumber: string) => {
  try {
    const { data } = await axios.post(`${API_URL_V3}/customers/login-with-phone`, {
      phoneNumber: phoneNumber,
      countryCode: countryCode,
    });
    return data;
  } catch (error) {
    const defaultErrorResponse = {
      success: false,
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
    
    return validateAuthenticationError(error, defaultErrorResponse)
  }
};
export const VERIFY_OTP_FOR_LOGIN_OR_REGISTER =
  async (token?: string, otpCode?: string) => {
    try {
      const { data } = await axios.post(`${API_URL_V3}/customers/otp/phone/verify`, {
        token: token,
        otpCodeToValidate: otpCode,
      }, {
        withCredentials: true
      });
      return data;
    } catch (error) {
      
      const defaultErrorResponse = {
        success: false,
        error: true,
        message: error.message || DEFAULT_ERROR_MESSAGE,
      };
      
      return validateAuthenticationError(error, defaultErrorResponse)
    }
  };


export const SIGN_UP_WITH_PHONE =
  async (token?: string, name?: string) => {
    try {
      const { data } = await axios.post(`${API_URL_V3}/customers/signup-with-phone`, {
        token: token,
        fullName: name,
      }, {
        withCredentials: true
      });
      return data;
    } catch (error) {
      
      const defaultErrorResponse = {
        success: false,
        error: true,
        message: error.message || DEFAULT_ERROR_MESSAGE,
      };
      
      return validateAuthenticationError(error, defaultErrorResponse)
    }
  };

export const VERIFY_OTP_CODE = async (otpCode: string, phoneNumber) => {
  try {
    const { data } = await axios.post(`${API_URL_V2}/user/verify-login-otp-code`, {
      phoneNumber: `${APP_CONFIG.countryCode}${phoneNumber}`,
      otpCode,
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};

export const generateOTPCodeWithMail = async (email: string) => {
  try {
    const { data, status } = await axios.post(`${API_URL_V2}/user/auth-email-generate-otp`, {
      email,
    });
    return { success: status === 200, data };
  } catch (error: any) {
    return { success: false };
  }
};

export const authWithEmailAndOtp = async (email: string, otpCode: string) => {
  try {
    const { data } = await axios.post(`${API_URL_V2}/user/auth-email-with-otp`, {
      email,
      otp: otpCode,
    });

    return {
      success: data.success,
      token: data.token,
      user: data.user,
      isNewUser: data.isNewUser,
      cookieOptions: data.cookieOptions,
    };
  } catch (error: any) {
    return {
      success: false,
      message: error?.response?.data?.message || 'Ha ocurrido un error',
    };
  }
};

export const completeSignUpWithEmailAndOtp = async (token: string, fullName: string) => {
  try {
    const { data } = await axios.post<ISignUpWithEmailResponse>(`${API_URL_V2}/user/auth-complete-user-information`, {
      token,
      name: fullName,
    });
    if (!data.success) {
      return {
        success: false,
        message: data?.message || 'Ha ocurrido un error',
      };
    }
    return {
      success: data.success,
      token: data.token,
      customer: data.customer,
      cookieOptions: data.cookieOptions,
    };
  } catch (error: unknown) {
    return {
      success: false,
      // @ts-ignore
      message: error?.response?.data?.message || 'Ha ocurrido un error',
    };
  }
};

export const signUpWithPhoneNumberService = async (token: string, fullName: string) => {
  try {
    const { data } = await axios.post<ISignUpWithPhoneResponse>(`${API_URL_V3}/customers/signup-with-phone`, {
      token,
      fullName,
    });
    if (!data.success) {
      return {
        success: false,
        message: data?.message || 'Ha ocurrido un error',
      };
    }
    return {
      success: data.success,
      token: data.token,
      customer: data.customer,
      cookieOptions: data.cookieOptions,
    };
  } catch (error: unknown) {
    return {
      success: false,
      // @ts-ignore
      message: error?.response?.data?.message || 'Ha ocurrido un error',
    };
  }
};

const validateAuthenticationError = (error: any, response: any) => {
  if (error.response) {
    const defaultErrorResponse = {
      error: true,
      message: error.response?.data?.message || 'Ocurrio un error',
      unauthorized: false,
    }
    if (error.response.status === 401) {
      defaultErrorResponse.message = error.response?.data?.message ||
        'OPs hemos tenido un problema por favor vuelve a iniciar sessión';
      defaultErrorResponse.unauthorized = true;
    }
    
    return defaultErrorResponse;
  } else {
    return response;
  }
}