import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsCart3, BsShop } from 'react-icons/bs';
import { MdArrowBackIosNew, MdOutlineHideImage } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../Layout/Loader';
import { getAllProductsShop } from '../../redux/actions/product';
import Cart from '../cart/Cart';
import { API_URL_V2 } from '../../server';

const ShopInfo = ({ data }) => {
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [openCart, setOpenCart] = useState(false);
  const dispatch = useDispatch();

  const cartCount = cart[id]?.products?.length || 0

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getAllProductsShop(id));
  }, []);


  // const totalReviewsLength = products && products.reduce((acc, product) => acc + product.reviews.length, 0);

  // const totalRatings =
  //   products && products.reduce((acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0), 0);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex w-full flex-col justify-between px-6 py-3 md:flex-row md:px-12">
            <div className="item-center flex">
              <div className="mr-4 self-center">
                <button className="inline-block max-h-min p-1" onClick={() => navigate('/')}>
                  <MdArrowBackIosNew className="h-6 w-6 text-gray-400" />
                </button>
              </div>
              {data.avatar?.url && <img src={`${data.avatar?.url}`} alt="" className="h-[4rem] w-[4rem] rounded-full object-cover" />}
              <div className="self-center px-2">
                <h3 className="self-center text-center text-lg">{data.name}</h3>
              </div>
            </div>
            <section className="w-ful flex justify-evenly gap-2  py-2 md:w-28 md:items-center md:justify-between md:py-0 md:pl-4">
              <button className="self-center">
                <BsShop className="h-6 w-6 " />
              </button>
              {/* <FavoriteShop shopId={data._id} /> */}
              <div className="h-5 w-px self-center bg-black"></div>
              <button className="relative flex self-center" onClick={() => setOpenCart(true)}>
                <BsCart3 className="h-6 w-6" />

                {cartCount > 0 && (
                  <div className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-[10px] font-bold text-white">
                    {cartCount}
                  </div>
                )}
              </button>
            </section>
          </div>
          {/* cart popup */}
          {/*{openCart ? <Cart setOpenCart={setOpenCart} shopId={id} isOpen={openCart} /> : null}*/}
          <Cart setOpenCart={setOpenCart} shopId={id} isOpen={openCart} />
        </div>
      )}
    </>
  );
};

export default ShopInfo;
