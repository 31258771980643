import { useState } from 'react';
import { toast } from 'react-toastify';

import MKDrawer from '../../../UI/MKDrawer';
import MKButton from '../../../UI/MKButton';
import { IoClose } from 'react-icons/io5';
import MKInput from '../../../UI/MKInput';
import loadingDefault from '../../../../Assests/animations/loading_blue_simple.json';
import Lottie from 'react-lottie/dist';
import { twMerge } from 'tailwind-merge';

interface ISignUpWithPhoneDrawerProps {
  isOpen?: boolean;
  isLoading?: boolean;
  handleCloseDrawer: () => void;
  handleSignUp?: (fullName?: string) => void;
}
const SignUpWithPhoneDrawer = ({ isOpen, isLoading, handleCloseDrawer, handleSignUp }: ISignUpWithPhoneDrawerProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleSignUpLocal = async () => {
    if (inputValue.trim().length === 0) {
      toast.info('Es necesario ingresar un nombre para registrarse');
      return;
    }
    
    handleSignUp(inputValue);
  };
  
  
  const loadingDefaultOption = {
    loop: true,
    autoplay: true,
    animationData: loadingDefault,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  
  return (
    <div
      className={twMerge(
        'fixed inset-0 z-40 bg-black/40 transition-opacity duration-300',
        isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      )}
      onClick={() => handleCloseDrawer() } // Cierra al hacer clic fuera del Drawer
    >
      <div
        className={twMerge(
          'fixed right-0 top-0 z-50 h-full bg-white shadow-sm flex flex-col',
          'transition-transform duration-300 overflow-hidden', // Evita scroll no deseado
          isOpen ? 'translate-x-0' : 'translate-x-full',
          'w-full sm:w-[90%] md:w-[70%] lg:w-[50%] xl:w-[30%] 2xl:w-[25%]'
        )}
        onClick={(e) => e.stopPropagation()} // Evita cerrar al hacer clic dentro
      >
        <section className="flex flex-col px-3 py-6">
          <button className="absolute right-4 top-1" onClick={handleCloseDrawer}>
            <IoClose className="h-8 w-8 hover:text-primary-500" />
          </button>
          <h3 className="text-2xl">Último paso!</h3>
          <p className="pb-4 pt-2">
            Ingresa tu {' '}
            <span className="text-primary-200">
              nombre
            </span>{' '}
            para registrarte.
          </p>
          <MKInput
            value={inputValue}
            className="pl-5 pr-5"
            onChange={(e) => setInputValue(e.target.value)}
          />
          {!isLoading ? (
            <MKButton onClick={handleSignUpLocal} className="my-8 w-full self-center md:w-2/4">
              <p className="text-center text-white">Registrate</p>
            </MKButton>
          ) : (
            <div className="flex w-full flex-col items-center justify-center">
              <Lottie options={loadingDefaultOption} width={100} height={100} />
              <p className="text-sm font-bold">Registrandose...</p>
            </div>
          )}
          
        </section>
      </div>
    </div>
  );
};

export default SignUpWithPhoneDrawer;
