import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmojiFlag } from 'countries-list';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

import { useCountryAndRegion } from '../../hooks/configuration/location.hooks';
import MKCard from '../../components/UI/MKCard';
import MKButton from '../../components/UI/MKButton';

import { IRegionsAndCity } from '../../interfaces/redux';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import { setSelectedCity, setSelectedRegion } from '../../redux/actions/configuration';
import { COUNTRY_COMPOSITION } from '../../utils/constants/common';
import { useMobile } from '../../hooks/common';

const ChooseRegion = () => {
  const { selectedRegion } = useAppSelector((state) => state.configuration);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { country, regions, loading } = useCountryAndRegion({ refetch: true });
  const { entityname } = useParams();
  const requestChangeRegion = country && entityname && entityname === COUNTRY_COMPOSITION.department ? 2 : 1;
  const [region, setRegion] = useState('')
  
  const [step, setStep] = useState(requestChangeRegion);
  const handleChangeStep = (change: number) => {
    setStep(change);
  };
  const handleChangeRegion = async (regionDetails: IRegionsAndCity) => {
    setRegion(regionDetails.name);
    dispatch(setSelectedRegion({ ...regionDetails }));
    setStep(3);
  };
  const handleSelectedCity = (city) => {
    dispatch(setSelectedCity({ ...city }));
    navigate('/');
  };

  return (
    <section className={`flex h-screen items-center justify-center`}>
      <img className="absolute bottom-0 left-0  h-72 w-72" src="/assets/food-bg-1.png" alt="" />
      <img className="-z-10 absolute right-0 top-0 h-72 w-72" src="/assets/food-bg-2.png" alt="" />

      <section className="m-auto w-5/6 md:w-2/4">
        <>
          <section className={`flex items-center gap-4 ${isMobile && 'flex-col content-center'}`}>
            <div className="justify-items-center">
              <div className={`flex self-center ${isMobile ? 'h-16 w-16' : 'h-40 w-44'} justify-center rounded-3xl bg-black p-4`}>
                <img className="z-50 h-full w-full md:h-32 md:w-32" src="/assets/mercoline-logo-300x300.png" alt="" />
              </div>
              <p className="text-center font-bold text-primary-500">Merkoline</p>
              <p className="text-center font-bold text-primary-200">FÁCIL RÁPIDO, SIN DISTANCIA</p>
            </div>
            {step === 1 && (
              <MKCard className={`min-h-80 ${isMobile ? 'mx-3 w-full' : 'w-9/12'} shadow-lg`}>
                <p className="text-2xl font-bold">Hola</p>
                <p className="text-lg ">¿En donde quieres pedir?</p>
                {!loading && country && (
                  <MKButton
                    onClick={() => handleChangeStep(2)}
                    variant="text"
                    className="my-2 flex w-full flex-row items-center justify-between rounded-md border border-gray-300 p-2"
                  >
                    <p>{country}</p>
                    <p className="text-3xl">{getEmojiFlag('NI')}</p>
                  </MKButton>
                )}
              </MKCard>
            )}
            {step === 2 && (
              <MKCard className={`min-h-80 ${isMobile ? 'mx-3 w-full' : 'w-9/12'} shadow-lg`}>
                <div className="flex gap-2 md:gap-4">
                  <MKButton
                    variant="text"
                    className="rounded-full border border-gray-300 p-2 hover:bg-primary-100"
                    onClick={() => handleChangeStep(1)}
                  >
                    <MdOutlineArrowBackIosNew />
                  </MKButton>
                  <div>
                    <p className="text-2xl font-bold">{country} {getEmojiFlag('NI')}</p>
                    <p className="text-lg ">Selecciona el departamento donde quieres pedir</p>
                  </div>
                </div>
                {!loading &&
                  regions.map((region) => (
                    <MKButton
                      onClick={() => handleChangeRegion(region)}
                      variant="text"
                      className="my-2 flex w-full flex-row items-center justify-between rounded-md border border-gray-300 p-2"
                    >
                      <p>{region.name}</p>
                    </MKButton>
                  ))}
              </MKCard>
            )}
            {step === 3 && (
              <MKCard className={`min-h-80 ${isMobile ? 'mx-3 w-full' : 'w-9/12'} shadow-lg`}>
                <div className="flex gap-2 md:gap-4">
                  <MKButton
                    variant="text"
                    className="rounded-full border border-gray-300 p-2 hover:bg-primary-100"
                    onClick={() => handleChangeStep(2)}
                  >
                    <MdOutlineArrowBackIosNew />
                  </MKButton>
                  <div>
                    <p className="text-2xl font-bold">{country} / {region}</p>
                    <p className="text-lg ">Selecciona la ciudad donde quieres pedir</p>
                  </div>
                </div>
                {!loading &&
                  selectedRegion.cities.map((city) => (
                    <MKButton
                      onClick={() => handleSelectedCity(city)}
                      variant="text"
                      className="my-2 flex w-full flex-row items-center justify-between rounded-md border border-gray-300 p-2"
                    >
                      <p>{city.name}</p>
                    </MKButton>
                  ))}
              </MKCard>
            )}
          </section>
        </>
      </section>
    </section>
  );
};

export default ChooseRegion;
